const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

export const WebPreviewBanner = () => {
    return (
        <div className="fixed bottom-0 left-0 right-0 bg-indigo-600 z-50">
            <div className="w-full mx-auto py-3 px-3 px-2">
                <div className="text-center flex gap-2 align-center items-center justify-center text-xs">
                    <p className="font-normal text-white">
                        Modo WebPreview Activo
                    </p>
                    <span className="block sm:ml-2 sm:inline-block">
                        <a
                            href={`${baseUrl}/api/exit-preview`}
                            className="underline text-white transition-colors"
                        >
                            Desactivar
                        </a>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default WebPreviewBanner